/* eslint-disable */
import HttpService from '../HttpService';
export default class GenderService {
    constructor() {
      this._httpService = new HttpService('contract');
    }

    async FindAll(){
        return await this._httpService.get( '/gender');
    }
 };
