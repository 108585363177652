<template>
  <v-container class="bg-glass-effect-lighter px-3 py-0" fluid>
    <v-dialog v-if="stepCount === 2" v-model="dialog.show" persistent>
      <v-card :style="dialog.style" rounded="lg">
        <v-row justify="center" class="ma-0 px-0 pt-4" style="height: 100%;">
          <v-col cols="11" align-self="start">
            <v-row>
              <v-col col="12">
                <h5 class="pb-5 generic-title" style="text-align: center; font-weight: bold;" :style="{color: dialog.colorDefault}"> {{ dialog.title }} </h5>
                <p class="px-5 text-dialog">{{ dialog.message.text }}</p>
                <p class="px-5 text-dialog"> {{ dialog.message.textLink }} <a target="_blank" :href="dialog.message.link">clique aqui</a>.</p>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="11" align-self="center">
            <v-btn class="btn btn-great mb-2" :color="dialog.colorDefault" block x-large rounded @click="onClickAccept()">Aceitar</v-btn>
            <v-btn class="btn btn-great btn-negative" block x-large rounded @click="onClickNotAccept()">Recusar</v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <v-dialog v-if="billingDialog.show" v-model="billingDialog.show" persistent>
      <v-card :style="billingDialog.style" rounded="lg">
        <v-row justify="center" class="ma-0 px-0 pt-4" style="height: 100%;">
          <v-col cols="12" align-self="start">
            <v-row>
              <v-col col="12">
                <p class="px-5 text-dialog" v-html="billingDialog.message.text"></p>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="11" align-self="center">
            <v-btn class="btn btn-great mb-2" :color="billingDialog.colorDefault" block x-large rounded @click="onClickBillingAccept()">Aceitar</v-btn>
            <v-btn class="btn btn-great btn-negative" block x-large rounded @click="onClickBillingNotAccept()">Recusar</v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <v-row class="py-0">
      <v-col>
        <img
            @click="$router.back()"
            class="arrow-position"
            src="/img/icons/common/back-arrow.svg"
        />
      </v-col>
      <v-col cols="12" class="ma-0 pa-0">
        <v-stepper class="stepper-layout" v-model="stepCount">
          <v-stepper-items>
            <v-stepper-content class="py-0 my-0" step="0">
              <v-row class="py-0 my-0">
                <!--start-->
                <v-col class="py-0 my-0" align-self="start" cols="12">
                  <v-row class="py-0 pt-5 my-0">
                    <v-col cols="12" align="center">
                      <v-avatar color="white">
                        <v-icon color="primary">fas fa-video</v-icon>
                      </v-avatar>
                    </v-col>
                    <v-col cols="12" align="center">
                      <h1 style="">{{ title }}</h1>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12">
                      <v-card rounded="lg">
                        <v-card-text>
                          <v-row>
                            <v-col>
                              <v-icon color="primary" class="pr-2">fas fa-exclamation-triangle</v-icon>
                              <span class="topic">Informação Importante</span>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col cols="12">
                              <p class="text-justify">Verificamos que você ainda não possui o benefício <strong>Telemedicina</strong>.</p>
                              <p class="text-justify">Adquira este benifício para poder ter acesso às funcionalidades de <strong>Agendamento</strong> e <strong>Plantão 24 horas</strong> na palma da sua mão.</p>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-col>

                <!--end-->
                <v-col align-self="end" cols="12">
                  <v-row>
                    <v-col cols="12">
                      <v-btn class="py-7 mb-4 btn btn-great mt-5" block rounded large color="primary" @click="$router.back()">Voltar</v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-stepper-content>


            <v-stepper-content class="py-0 my-0" step="1">
              <v-row class="py-0 my-0">
                <!--start-->
                <v-col class="py-0 my-0" align-self="start" cols="12">
                  <v-row class="py-0 pt-5 my-0">
                    <v-col cols="12" align="center">
                      <v-avatar color="white">
                        <v-icon color="primary">fas fa-video</v-icon>
                      </v-avatar>
                    </v-col>
                    <v-col cols="12" align="center">
                      <h1 style="">{{ title }}</h1>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12">
                      <v-card rounded="lg">
                        <v-card-text>
                          <v-list rounded>
                            <v-subheader>Selecione o tipo de atendimento</v-subheader>
                            <v-list-item-group color="primary" v-model="telemedicineSelected">
                              <v-list-item v-for="item in telemedicineOptions" :key="item.id">
                                <v-list-item-icon>
                                  <v-icon v-text="item.icon"></v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                  <v-list-item-title color="primary" v-text="item.text"></v-list-item-title>
                                  <v-list-item-action-text color="primary" v-text="item.subtext"></v-list-item-action-text>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list-item-group>
                          </v-list>

                          <v-divider v-if="telemedicineSelected !== null" class="ma-4"></v-divider>

                          <p class="px-4 text-justify" v-if="telemedicineSelected !== null" v-html="telemedicineOptions[telemedicineSelected].subtitle"></p>

                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-col>

                <!--end-->
                <v-col align-self="end" cols="12">
                  <v-row>
                    <v-col cols="12">
                      <v-btn class="py-7 mb-4 btn btn-great mt-5" block rounded large color="primary" @click="onClickFirstStep()" :disabled="telemedicineSelected === null">Próximo</v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-stepper-content>

            <v-stepper-content class="py-0 my-0" step="2">
              <v-row class="py-0 my-0">
                <!--start-->
                <v-col class="py-0 my-0" align-self="start" cols="12">
                  <v-row class="py-0 pt-5 my-0">
                    <v-col cols="12" align="center">
                      <v-avatar color="white">
                        <v-icon color="primary">fas fa-video</v-icon>
                      </v-avatar>
                    </v-col>
                    <v-col cols="12" align="center">
                      <h1 style="">{{ title }}</h1>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12">
                      <v-card rounded="lg">
                        <v-card-text>
                          <v-autocomplete
                              v-model="specialitySelected"
                              :items="speciality"
                              item-text="especialidade"
                              return-object
                              class="my-2"
                              label="Selecione a Especialidade"
                              color="primary"
                              @input="loadAccreditedCompanies()">
                          </v-autocomplete>

                          <span v-if="specialitySelected && accreditedCompanies && accreditedCompanies.length > 1" class="pt-1 topic">Credenciado</span>
                          <v-autocomplete
                              v-if="specialitySelected && accreditedCompanies && accreditedCompanies.length > 1"
                              :items="accreditedCompanies"
                              v-model="accreditedCompanySelected"
                              @change="verifyTelemedicineBilling()"
                              return-object
                              class="my-2"
                              item-text="credenciado"
                              placeholder="Selecione a Empresa Credenciada"
                              color="primary">
                          </v-autocomplete>

                          <v-autocomplete
                              v-if="accreditedCompanySelected"
                              :items="attendees"
                              v-model="attendeeSelected"
                              item-text="name"
                              return-object
                              class="my-2"
                              label="Usuário atendido"
                              color="primary">
                          </v-autocomplete>

                          <v-switch v-if="accreditedCompanySelected" v-model="scheduledAppointment.reconsulta" label="Consulta de Retorno"></v-switch>
                        </v-card-text>
                      </v-card>

                      <v-card rounded="lg" class="mt-7 pt-1" v-if="attendeeSelected">
                        <v-card-text>

                          <v-form v-model="isFormInvalid" ref="formTelemedicine" lazy-validation>
                            <v-row>
                              <v-col cols="12">
                                <span class="topic">Dados Pessoais</span>
                              </v-col>
                              <v-col cols="12" class="pt-1 pb-5">
                                <v-row>
                                  <v-col cols="12">
                                    <span style="font-size: 12px;">CPF</span>
                                  </v-col>
                                </v-row>
                                <span class="text--primary" style="font-size: 16px;">{{attendeeSelected.cpf ? attendeeSelected.cpf : '-'}}</span>
                              </v-col>
                              <v-col cols="12" class="pt-1 pb-5">
                                <v-row>
                                  <v-col cols="12">
                                    <span style="font-size: 12px;">Data de Nascimento</span>
                                  </v-col>
                                </v-row>
                                <span class="text--primary" style="font-size: 16px;">{{attendeeSelected.birthDate ? attendeeSelected.birthDate : '-'}}</span>
                              </v-col>
                              <v-col cols="12" class="py-1">
                                <v-text-field label="Celular" type="tel" v-model="attendeeSelected.phone" v-mask="$util.formmasks.dddTel" :rules="[rules.requiredPhone]"></v-text-field>
                              </v-col>
                              <v-col cols="12" class="py-1">
                                <v-text-field label="E-mail" type="email" v-model="attendeeSelected.email" :rules="[rules.email, rules.requiredText]"></v-text-field>
                              </v-col>
                              <v-col cols="12" class="py-1">
                                <v-select label="Gênero" :items="biologicGenders" v-model="attendeeSelected.biologicGender" item-value="code" item-text="description" color="primary" :rules="[rules.requiredArray]"></v-select>
                              </v-col>
                              <!-- <v-col cols="12" class="py-1">
                                <v-select label="Gênero" :items="genders" v-model="attendeeSelected.gender" item-value="code" item-text="description" color="primary" :rules="[rules.requiredArray]"></v-select>
                              </v-col> -->
                            </v-row>
                          </v-form>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-col>

                <!--end-->
                <v-col align-self="end" cols="12">
                  <v-row>
                    <v-col cols="12">
                      <v-btn class="py-7 mb-4 btn btn-great mt-5" block rounded large color="primary" :disabled="!attendeeSelected || !specialitySelected" @click="onClickSecondStep()">Próximo</v-btn>
                      <v-btn class="py-7 mb-4 btn btn-great" block rounded large @click="stepCount--">Voltar</v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-stepper-content>

            <v-stepper-content class="py-0 my-0" step="3">
              <v-row class="py-0 my-0">
                <!--start-->
                <v-col class="py-0 my-0" align-self="start" cols="12">
                  <v-row class="py-0 pt-5 my-0">
                    <v-col cols="12" align="center">
                      <v-avatar color="white">
                        <v-icon color="primary">fas fa-video</v-icon>
                      </v-avatar>
                    </v-col>
                    <v-col cols="12" align="center">
                      <h1 style="">{{ title }}</h1>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12">
                      <v-card rounded="lg">
                        <v-card-text>
                          <span>Selecione as datas de sua preferência para o agendamento</span>
                          <v-col class="mt-4 px-0 mx-0" cols="12">
                            <v-date-picker width="100%" v-model="dateSelected" color="primary" :disabled="dateSelecteds && dateSelecteds.length === 3" :min="moment().add(1, 'days').format('YYYY-MM-DD')">
                            </v-date-picker>
                          </v-col>

                          <v-divider class="py-3"></v-divider>

                          <v-col cols="12">
                            <span>Selecione o turno de sua preferência para o agendamento</span>
                            <v-row>
                              <v-col cols="12" align="center">
                                <v-radio-group v-model="periodSelected" :disabled="!dateSelected || (dateSelecteds && dateSelecteds.length === 3)">
                                  <v-radio
                                      v-for="(period, index) in periods"
                                      :key="index"
                                      :label="period.text"
                                      :value="period"
                                      :disabled="period.disabled"
                                  ></v-radio>
                                </v-radio-group>
                              </v-col>
                            </v-row>
                          </v-col>

                          <v-col cols="12">
                            <v-btn class="py-7 mb-4 btn btn-great" color="primary" text block rounded large :disabled="!dateSelected || !periodSelected" @click="addDateAndPeriod()">
                              <v-icon left>fas fa-calendar-plus</v-icon> Adicionar
                            </v-btn>
                          </v-col>
                        </v-card-text>
                      </v-card>
                    </v-col>

                    <v-col cols="12" v-if="dateSelecteds && dateSelecteds.length">
                      <v-card rounded="lg">
                        <v-card-text>
                          <span>Datas e períodos selecionados</span>
                          <v-col class="px-0 mx-0" cols="12">
                            <v-chip class="mb-1" close v-for="(item, index) in dateSelecteds" :key="index" @click:close="onClickDeleteDate(index)"> {{item.text}}</v-chip>
                          </v-col>
                          <span>Máximo: 03 datas/períodos</span>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-col>

                <!--end-->
                <v-col align-self="end" cols="12">
                  <v-row>
                    <v-col cols="12">
                      <v-btn class="py-7 mb-4 btn btn-great mt-5" block rounded large color="primary" :disabled="!dateSelecteds || (dateSelecteds && dateSelecteds.length === 0)" @click="onClickThirdStep()">Solicitar</v-btn>
                      <v-btn class="py-7 mb-4 btn btn-great" block rounded large @click="stepCount--">Voltar</v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-stepper-content>

            <v-stepper-content class="py-0 my-0" step="4">
              <v-row class="py-0 my-0">
                <!--start-->
                <v-col class="py-0 my-0" align-self="start" cols="12">
                  <v-row class="py-0 pt-5 my-0">
                    <v-col cols="12" align="center">
                      <v-avatar color="white">
                        <v-icon color="primary">fas fa-video</v-icon>
                      </v-avatar>
                    </v-col>
                    <v-col cols="12" align="center">
                      <h1 style="">{{ title }}</h1>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12">
                      <v-card rounded="lg">
                        <v-card-text>
                          <v-row>
                            <v-col>
                              <v-icon color="primary" class="pr-2">fas fa-check-circle</v-icon>
                              <span class="topic">Solicitação Enviada</span>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col cols="12">
                              <p class="text-justify">A sua solicitação de agendamento foi enviada com sucesso.</p>
                              <p class="text-justify">Em breve, um atendente entrará em contato para dar prosseguimento no agendamento.</p>
                              <p></p>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-col>

                <!--end-->
                <v-col align-self="end" cols="12">
                  <v-row>
                    <v-col cols="12">
                      <v-btn class="py-7 mb-4 btn btn-great mt-5" block rounded large color="primary" @click="$router.back()">Finalizar</v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from 'moment';
import {mapMutations} from "vuex";
import {regexEmail} from "@/common/constants";
import GenderService from '@/services/contract/GenderService';
import TelemedicineService from "@/services/euprotegido/TelemedicineService";
import MedicalAppointmentEventService from "@/services/healthcare/MedicalAppointmentEvent";

export default {
  name: "telemedicine",
  data: () => ({
    moment,
    countryCode: 'BR',
    stepCount: 1,
    attendeeSelected: null,
    attendees: [],
    accreditedCompanySelected: {
      id: null,
      coberturaprecoid: null
    },
    accreditedCompanies: [],
    dateSelected: null,
    dateSelecteds: [],
    dateMenu: false,
    biologicGenders: [],
    dialog: {
      message: {
        text: 'Para prosseguir você deve aceitar os termos e condições.',
        textLink: 'Para ler os termos,',
        link: '#',
      },
      title: 'Termos e Condições',
      show: true,
      colorDefault: 'primary',
      style: {
        color: '#532E88',
        backgroundColor: '#EEEDF7',
      }
    },
    billingDialog: {
      message: {
        text: 'Seu limite  de consultas gratuitas foi atingido ou a especialidade selecionada não está contemplada na ' +
            'gratuidade. Desta forma, o atendimento será cobrado e o custo desta consulta <span style="font-weight: bolder"><u><b>é de %s reais.</b></u></span> ' +
            'Deseja prosseguir com o agendamento?',
      },
      show: false,
      colorDefault: 'primary',
      style: {
        color: '#532E88',
        backgroundColor: '#EEEDF7',
      }
    },
    genders: [
      { description: 'AGÊNERO', code: 'Agenero'},
      { description: 'FEMININO', code: 'Feminino'},
      { description: 'GÊNERO FLUÍDO', code: 'GeneroFluido'},
      { description: 'MASCULINO', code: 'Masculino'},
      { description: 'NÃO BINÁRIO', code: 'NaoBinario'},
      { description: 'QUEER', code: 'Queer'},
      { description: 'TRANS (FEMININO)', code: 'TransFeminino'},
      { description: 'TRANS (MASCULINO)', code: 'TransMasculino'},
      { description: '* NÃO INFORMAR', code: 'NaoInformar'},
    ],
    periodSelected: null,
    periods: [
      { text: 'Manhã', value: 'manha', disabled: false },
      { text: 'Tarde', value: 'tarde', disabled: false },
      { text: 'Integral', value: 'integral', disabled: false }
    ],
    telemedicineSelected: null,
    telemedicineOptions: [
      { id: 0, text: 'Agendamento', subtext: 'Especialistas por vídeo com hora marcada', icon: 'fas fa-notes-medical', subtitle: 'Você poderá realizar o agendamento de consultas médicas de maneira fácil, rápida e segura. Clique em <b>Próximo</b> para continuar.' },
      { id: 1, text: 'Plantão 24 horas', subtext: 'Pronto atendimento por vídeo com clínico geral', icon: 'fas fa-user-md', subtitle: 'Você poderá realizar uma consulta online com <b>Clínico Geral</b> ou <b>Pediatra</b>  no horário mais adequado para o seu dia. Clique em <b>Próximo</b> para continuar.' }
    ],
    specialitySelected: null,
    speciality: [],
    isFormInvalid: false,
    rules: {
      requiredPhone: (value) => value && value.length >= 14 || 'Campo obrigatório',
      requiredText: (value) => !!value || 'Campo obrigatório',
      requiredArray: (value) => (value && value.length > 0) || 'Campo obrigatório',
      email: (value) => {
        if (value && value.length > 0) {
          const pattern = regexEmail;
          return pattern.test(value) || 'E-mail inválido';
        }
        return true;
      }
    },
    user: null,
    title: 'Telemedicina',
    scheduledAppointment: {
      contato: {
        email: null,
        telefone: null
      },
      cpfpaciente: null,
      credenciado: {
        id: null,
        especialidade: {
          id: null,
          especialidade: null,
        }
      },
      reconsulta: false,
      valorconsulta: 0,
      sugestoesagenda: [],
      userId: 0
    }
  }),
  async created() {
    this.genderService = new GenderService();
    this.telemedicineService = new TelemedicineService();
    this.appointmentEventService = new MedicalAppointmentEventService();

    this.loading(true);

    if(this.$auth.user() && this.$auth.user().beneficiaries && this.existsBenefitTelemedicine(this.$auth.user().beneficiaries)) {
      this.loadAttendeeNamesByBeneficiary();
    } else if (this.$auth.user() && this.$auth.user().dependents && this.existsBenefitTelemedicine(this.$auth.user().dependents)) {
      this.loadAttendeeNamesByDependent();
    } else {
      this.stepCount = 0;
    }

    if(this.stepCount !== 0){
      await this.loadURLTerms();
      await this.loadGenders();
      await this.loadSpecialities();
    }
    this.loading(false);
  },
  watch: {
    dateSelected(newValue) {
      const hourLimit = 19;
      const tomorrow = moment().add(1, 'days').format('YYYY-MM-DD');

      if(newValue && moment(newValue).isSame(tomorrow)) {
        const actualHour = moment().format('HH');
        if(actualHour && parseInt(actualHour) >= hourLimit) {
          this.periods[0].disabled = true;
        }
      } else {
        this.periods[0].disabled = false;
      }

    }
  },
  methods:{
    ...mapMutations(['showmsg', 'loading']),
    async loadURLTerms() {
      await this.appointmentEventService.getURLFromTelemedicineScheduleTerms()
          .then((result) => {
            if(result && result.data) {
              this.dialog.message.link = result.data;
            }
          })
          .catch(() => {
            this.showmsg({text: 'Ocorreu um erro ao tentar carregar os Termos e Condições de Uso.', type: 'error'});
            this.loading(false);
          });
    },
    async loadGenders() {
      await this.genderService.FindAll().then((response) => {
        if (response && response.data) {
          this.biologicGenders = response.data;
        }
      });
    },
    async loadSpecialities() {
      this.loading(true);
      await this.telemedicineService.FindAllSpecialities()
          .then((response) => {
            if(response && response.data) {
              this.speciality = response.data;
            }
          })
          .catch(() => {
            this.showmsg({text: 'Não foi possível carregar as especialidades.', type: "error"});
          })
          .finally(() => {
            this.loading(false);
          });
    },
    async loadAccreditedCompanies() {
      this.loading(true);
      if(this.specialitySelected) {
        await this.telemedicineService.FindAccreditedBySpecialityId(this.specialitySelected.id)
            .then((response) => {
              if(response && response.data) {
                this.accreditedCompanies = response.data
                if(this.accreditedCompanies.length === 1) {
                  this.accreditedCompanySelected = {};
                  this.accreditedCompanySelected.id = this.accreditedCompanies[0].id;
                  this.accreditedCompanySelected.coberturaprecoid = this.accreditedCompanies[0].coberturaprecoid;
                  this.accreditedCompanySelected.valorconsulta = this.accreditedCompanies[0].valorconsulta;
                  this.verifyTelemedicineBilling()
                }
              }
            })
            .catch((error) => {
              this.accreditedCompanySelected = null;
              this.attendeeSelected = null;
              if(error && error.response && error.response.data && error.response.data.includes('Plano não possui cobertura')) {
                this.showmsg({text: 'O plano telemedicina não possui cobertura para esta especialidade.', type: "error"});
              } else {
                this.showmsg({text: 'Não foi possível carregar as empresas credenciadas.', type: "error"});
              }
            })
            .finally(() => {
              this.loading(false);
            });
      }
    },
    async createScheduledAppointment() {
      this.loading(true);
      const phone = this.attendeeSelected.phone ? this.$util.removerPontuacaoCPF(this.attendeeSelected.phone) : null;
      this.scheduledAppointment.userId = this.attendeeSelected.userId;
      this.scheduledAppointment.dependentId = this.attendeeSelected.dependentId;
      this.scheduledAppointment.contato.email = this.attendeeSelected.email;
      this.scheduledAppointment.contato.areaCode = phone ? phone.slice(0, 2) : null;
      this.scheduledAppointment.contato.telefone = phone ? phone.slice(2, phone.length) : null;
      this.scheduledAppointment.cpfpaciente = this.attendeeSelected.cpf ? this.$util.removerPontuacaoCPF(this.attendeeSelected.cpf) : null;
      this.scheduledAppointment.coberturaprecoid = this.accreditedCompanySelected.coberturaprecoid;
      this.scheduledAppointment.credenciado.id = this.accreditedCompanySelected.id;
      this.scheduledAppointment.credenciado.especialidade.id = this.specialitySelected.id;
      this.scheduledAppointment.credenciado.especialidade.especialidade = this.specialitySelected.especialidade;
      this.loadCombinedDateAndPeriod();
      await this.appointmentEventService.createTelemedicineAppointment(this.scheduledAppointment, this.$util.removerPontuacaoCPF(this.attendeeSelected.holderCpf))
          .then(() => {
            this.updateSessionData(this.attendeeSelected.name, this.scheduledAppointment);
            this.stepCount++;
          })
          .catch((error) => {
            if(error.response && error.response.data && error.response.data.status === 409) {
              this.showmsg({text: error.response.data.message, type: "error"});
            } else {

              if(error && error.response && error.response.data && error.response.data.message && error.response.data.message.includes('Especialidade não está presente')) {
                this.showmsg({text: 'Não foi possível solicitar o agendamento. A especialidade não está presente.', type: "error"});
              } else if (error && error.response && error.response.data && error.response.data.message && error.response.data.message.includes('Sugestao de agenda inválida')) {
                this.showmsg({text: 'Agenda inválida. Selecione uma agenda com datas posteriores à data atual.', type: "error"});
              } else {
                this.showmsg({text: 'Não foi possível solicitar o agendamento.', type: "error"});
              }
            }

          })
          .finally(() => {
            this.loading(false);
          });
    },
    loadAttendeeNamesByBeneficiary() {
      const beneficiary = this.$auth.user().beneficiaries.find(contractedPlan => contractedPlan.benefitName === this.title);
      let attendee = {
        name: beneficiary.personName ? `${beneficiary.personName} (Titular)` : null,
        cpf: beneficiary.personCPF ? this.$util.formatCPF(beneficiary.personCPF) : null,
        biologicGender: beneficiary.personGender ? beneficiary.personGender.toLowerCase() : null,
        gender: null,
        birthDate: beneficiary.personBirthDate ? moment(beneficiary.personBirthDate).format('DD/MM/YYYY') : null,
        phone: beneficiary.personCellPhoneDDD && beneficiary.personCellPhoneNumber ? this.$util.formatPhone((beneficiary.personCellPhoneDDD + beneficiary.personCellPhoneNumber))
            : (beneficiary.personCellPhoneDDD ? beneficiary.personCellPhoneDDD : (beneficiary.personCellPhoneNumber ? beneficiary.personCellPhoneNumber : '')),
        email: beneficiary.personEmail ? beneficiary.personEmail : null,
        userId: beneficiary.userId,
        holderCpf: beneficiary.personCPF ? this.$util.formatCPF(beneficiary.personCPF) : null
      }

      this.attendees.push(attendee);

      if(beneficiary && beneficiary.dependents.length) {

        const dependent = beneficiary.dependents.find(contractedPlan => contractedPlan.benefitName === this.title);
        if(dependent) {
          beneficiary.dependents.forEach(dependent => {
            if(this.attendees.every(attendee => attendee.cpf !== dependent.personCPF) && this.$util.isDependentGreaterThanAgeLimit(dependent.personBirthDate)) {
              attendee = {
                name: dependent.personName ? `${dependent.personName} (Dependente)` : null,
                cpf: dependent.personCPF ? this.$util.formatCPF(dependent.personCPF) : null,
                biologicGender: dependent.personGender ? dependent.personGender.toLowerCase() : null,
                gender: null,
                birthDate: dependent.personBirthDate ? moment(dependent.personBirthDate).format('DD/MM/YYYY') : null,
                phone: dependent.personCellPhoneDDD && dependent.personCellPhoneNumber ? this.$util.formatPhone((dependent.personCellPhoneDDD + dependent.personCellPhoneNumber))
                    : (dependent.personCellPhoneDDD ? dependent.personCellPhoneDDD : (dependent.personCellPhoneNumber ? dependent.personCellPhoneNumber : '')),
                email: dependent.personEmail ? dependent.personEmail : null,
                userId: dependent.userId,
                dependentId: dependent.dependentId,
                holderCpf: beneficiary.personCPF ? this.$util.formatCPF(beneficiary.personCPF) : null
              }
              this.attendees.push(attendee);
            }
          });
        }
      }
    },
    loadAttendeeNamesByDependent() {
      const dependent = this.$auth.user().dependents.find(contractedPlan => contractedPlan.benefitName === this.title);

      let attendee = {
        name: dependent.personName ? `${dependent.personName} (Dependente)` : null,
        cpf: dependent.personCPF ? this.$util.formatCPF(dependent.personCPF) : null,
        biologicGender: dependent.personGender ? dependent.personGender.toLowerCase() : null,
        gender: null,
        birthDate: dependent.personBirthDate ? moment(dependent.personBirthDate).format('DD/MM/YYYY') : null,
        phone: dependent.personCellPhoneDDD && dependent.personCellPhoneNumber ? this.$util.formatPhone((dependent.personCellPhoneDDD + dependent.personCellPhoneNumber))
            : (dependent.personCellPhoneDDD ? dependent.personCellPhoneDDD : (dependent.personCellPhoneNumber ? dependent.personCellPhoneNumber : '')),
        email: dependent.personEmail ? dependent.personEmail : null,
        userId: dependent.userId,
        dependentId: dependent.dependentId,
        holderCpf: dependent.holderCPF
      }

      this.attendees.push(attendee);
    },
    existsBenefitTelemedicine(contractedPlans) {
      return contractedPlans.length && contractedPlans.some(contractedPlan => contractedPlan.benefitName === this.title);
    },
    addDateAndPeriod() {
      if(this.dateSelected && this.periodSelected) {
        if(this.dateSelecteds.some(element => element.date === this.dateSelected && element.period === this.periodSelected.value)) {
          this.showmsg({text: "Esta data/período já foi escolhida. Por favor, escolha outra data/período.", type: "error"});
        } else {
          this.dateSelecteds.push({
            text: moment(this.dateSelected).format('DD/MM/YYYY') + ' - ' + this.periodSelected.text,
            date: this.dateSelected,
            period: this.periodSelected.value,
          });
          this.dateSelecteds.sort((a, b) => (a.text > b.text ? 1 : -1))
        }
      }

      this.dateSelected = null;
      this.periodSelected = null;
    },
    loadCombinedDateAndPeriod() {
      this.scheduledAppointment.sugestoesagenda = [];
      if(this.dateSelecteds && this.dateSelecteds.length) {
        this.dateSelecteds.forEach(item => {
          this.scheduledAppointment.sugestoesagenda.push({ data: item.date, turno: item.period})
        });
      }
    },
    updateSessionData(attendeeName, scheduledAppointment) {
      if(attendeeName && scheduledAppointment) {
        const user = this.$auth.user();

        if(this.$auth.user().beneficiaries && this.$auth.user().beneficiaries.length) {

          if(attendeeName.includes('(Titular)')) {
            const beneficiaries = this.$auth.user().beneficiaries;
            beneficiaries.forEach(beneficiary => {
              beneficiary.personEmail = scheduledAppointment.contato ? scheduledAppointment.contato.email : null;
              beneficiary.personCellPhoneDDD = scheduledAppointment.contato ? scheduledAppointment.contato.areaCode : null;
              beneficiary.personCellPhoneNumber = scheduledAppointment.contato ? scheduledAppointment.contato.telefone : null;
            });

            user.beneficiaries = beneficiaries;
            this.$auth.setUser(user);
          } else {

            const beneficiaries = this.$auth.user().beneficiaries;
            beneficiaries.forEach(beneficiary => {
              const dependents = beneficiary.dependents;
              if(dependents && dependents.length) {
                dependents.forEach(dependent => {
                  if(dependent && dependent.personCPF && scheduledAppointment.cpfpaciente && dependent.personCPF === scheduledAppointment.cpfpaciente) {
                    dependent.personEmail = scheduledAppointment.contato ? scheduledAppointment.contato.email : null;
                    dependent.personCellPhoneDDD = scheduledAppointment.contato ? scheduledAppointment.contato.areaCode : null;
                    dependent.personCellPhoneNumber = scheduledAppointment.contato ? scheduledAppointment.contato.telefone : null;
                  }
                })
              }
            });

            user.beneficiaries = beneficiaries;
            this.$auth.setUser(user);
          }

        } else if(this.$auth.user().dependents && this.$auth.user().dependents.length) {
          const dependents = this.$auth.user().dependents;
          dependents.forEach(dependent => {
            dependent.personEmail = scheduledAppointment.contato ? scheduledAppointment.contato.email : null;
            dependent.personCellPhoneDDD = scheduledAppointment.contato ? scheduledAppointment.contato.areaCode : null;
            dependent.personCellPhoneNumber = scheduledAppointment.contato ? scheduledAppointment.contato.telefone : null;
          });

          user.dependents = dependents;
          this.$auth.setUser(user);
        }
      }
    },
    onClickDeleteDate(index) {
      if(this.dateSelecteds && this.dateSelecteds.length) {
        this.dateSelecteds.splice(index, 1);
      }
    },
    onClickFirstStep() {
      if(this.telemedicineSelected === this.telemedicineOptions[0].id){
        this.attendeeSelected = null;
        this.specialitySelected = null;
        this.accreditedCompanySelected = null;
        this.stepCount++;
      } else {
        this.$router.push({ name: 'telemedicineDuty' });
      }
    },
    onClickSecondStep() {
      if(!this.$refs.formTelemedicine.validate()) {
        this.showmsg({text: "Por favor, preencha os campos obrigatórios.", type: "error"});
        return;
      }
      this.dateSelected = null;
      this.periodSelected = null;
      this.dateSelecteds = [];
      this.stepCount++;
    },
    onClickThirdStep() {
      this.createScheduledAppointment();
    },
    onClickAccept() {
      this.dialog.show = false;
    },
    onClickNotAccept() {
      this.$router.back();
    },
    onClickBillingAccept() {
      this.billingDialog.show = false;
    },
    onClickBillingNotAccept() {
      this.$router.back();
    },
    verifyTelemedicineBilling(){
      if(this.accreditedCompanySelected.valorconsulta && this.accreditedCompanySelected.valorconsulta > 0) {
        this.accreditedCompanySelected.valorconsulta = this.accreditedCompanySelected.valorconsulta.toLocaleString('pt-br', {minimumFractionDigits: 2});
        this.billingDialog.show = true;
        this.billingDialog.message.text = this.billingDialog.message.text.replace("%s", this.accreditedCompanySelected.valorconsulta)
      }
    }
  }
}
</script>

<style>

a {
  font-weight: bold;
  text-decoration: none;
  color: var(--v-primary) !important;
}

h1 {
  color: var(--v-primary) !important;
}

span.topic {
  color: var(--v-primary) !important;
}

.bg-glass-effect-lighter {
  height: 100% !important;
}

.stepper-layout {
  box-shadow: none !important;
  background-color: transparent !important;
}

.btn {
  text-transform: capitalize;
  font-weight: bold;
  font-family: Open Sans;
  font-size: 15px;
}

.btn-great {
  font-size: 18px;
}

.arrow-position {
  width: 28px;
  height: 23px;
  position: absolute;
  z-index: 10000;
  left: 25px;
  top: 15px;
}

.centered-input >>> input {
  text-align: center;
}

.text-dialog {
  text-align: center;
  color: #666;
}

</style>


