/* eslint-disable */
import HttpService from '../HttpService';
export default class TelemedicineService {
  constructor() {/* eslint-disable */
    this._httpService = new HttpService(`euprotegido`);
  }

  async FindAccreditedBySpecialityId(specialityId) {
    return await this._httpService.get('/telemedicine/accredited', { specialityId });
  }

  async FindAllSpecialities(){
    return await this._httpService.get('/telemedicine/accredited/specialities');
  }

  async FindCities(state) {
    return await this._httpService.get('/telemedicine/accredited/cities', { state });
  }

  async CreateScheduledAppointment(data, holderCpf) {
    return await this._httpService.post('/telemedicine/request?cpf=' + holderCpf, data);
  }

  async CreateURLTelemedicineDuty(holderCpf, cpf) {
    return await this._httpService.post(`/telemedicine/request/duty?holderCpf=${holderCpf}&cpf=${cpf}`);
  }
}

